<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Marketing Tools</v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-group
          :value="false"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-avatar>
              <v-icon>mdi-crosshairs-gps</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>Current Campaign</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option1"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option1"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-avatar>
              <v-icon>mdi-sim-outline</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>Prepaid POSM</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option2"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option2"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-avatar>
              <v-icon>mdi-sim</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>FC POSM</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option3"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option3"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group
          :value="false"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-avatar>
              <v-icon>mdi-archive-search-outline</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>Archive</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option5"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option5"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group
          v-for="item in option4"
          :key="item.title"
          :prepend-icon="item.icon"
          no-action
        >
          <template #activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <v-list-tile
            v-for="subItem in item.option4"
            :key="subItem.title"
            :href="subItem.link"
            target="_blank"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const pageTitle = 'Marketing Tools'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Downloads',
          disabled: false,
          to: '/downloads',
        },
        {
          text: pageTitle,
          disabled: true,
        },
      ],
      option1: [],
      option2: [],
      option3: [],
      option4: [],
      option5: [],
      title: pageTitle,
    }
  },

  created: function () {
    const params = createGetParams({})
    this.$rest
      .get('getDocuments1.php', params)
      .then((response) => {
        this.option1 = response.data.option1
        this.option2 = response.data.option2
        this.option3 = response.data.option3
        this.option4 = response.data.option4
        this.option5 = response.data.option5
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>
